<template>
  <div>
    <div v-if="testlist2.length>0">
      <div class="testType">
        <slot />
        <span v-if="isReleased == 1" class="score">
          得分: <span style="color: red">{{ score }}
          </span> 分
        </span>
      </div>
      <div v-if="hasResult == 0" class="testList">
        <div v-for="(item,index) in testlist2" :key="item.id" class="answer" @click="changeActiveAnswer(testType,index,item.id)">
          <!--                    <div v-if="item.answerState=='answered'" class="answered" >{{index+1}}</div>-->
          <!--                    <div v-else-if="item.answerState=='answering'" class="answering" >{{index+1}}</div>-->
          <!--                    <div v-else class="unanswered">{{index+1}}</div>-->
          <div v-if="currentType==testType&&currentIndex==index+1" class="answering">{{ index+1 }}</div>
          <div v-else-if="item.studentAnswer" class="answered">{{ index+1 }}</div>
          <div v-else class="unanswered">{{ index+1 }}</div>
        </div>
      </div>
      <div v-else class="testList">
        <div v-for="(item,index) in testlist2" :key="item.id" class="answer" @click="changeActiveAnswer(testType,index,item.id)">

          <div v-if="isReleased==0&&currentType==testType&&currentIndex==index+1" class="answering">{{ index+1 }}</div>
          <div v-else-if="isReleased==0" class="answered">{{ index+1 }}</div>

          <div v-else-if="isReleased==1&&item.results==0" class="rightAnswer">{{ index+1 }}</div>
          <!--                    <div v-else-if="item.answerState=='answering'" class="answering" >{{index+1}}</div>-->
          <div v-else-if="isReleased==1&&(testType==1||testType==2)" class="wrongAnswer">{{ index+1 }}</div>
          <div v-else class="defaultAnswer">{{ index+1 }}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Testlist',
  props: {
    hasResult: {
      type: [Boolean, Number, String],
      default() {
        return 0
      }
    },
    currentType: {
      'type': Number,
      'default': 1
    },
    score: {
      'type': Number,
      'default': 0
    },
    publishType: {
      'type': String,
      'default': '0'
    },
    isReleased: {
      'type': String,
      'default': '0'
    },
    currentIndex: {
      'type': Number,
      'default': 1
    },
    // 题目类型
    testType: {
      'type': [String, Number],
      'default': 1
    },
    // 题目列表
    testlist: {
      'type': [Object, Array],
      'default'() {}
    }
  },
  data() {
    return {
      testlist2: this.testlist
    }
  },
  created() {
    console.log(this.hasResult)
  },
  methods: {
    //  更改选中状态
    changeActiveAnswer(testType, index, id) {
      if (this.hasResult == 1) {
        this.$emit('onlyChange', testType, index, id)
      } else {
        // 子传父更改显示题型和答题部分
        this.$emit('changeTestType', testType, index, id)
      }
      // this.testlist2 =  this.testlist2.map((item=>{
      //     return {...item,answerState:item.answerState == 'answering'?'answered':item.answerState}
      // }))
      // this.testlist2[index].answerState = 'answering'
      // 保存该题
      // this.$emit("saveCurrent",testType,index)
    }
  }
}
</script>

<style scoped lang="less">
    .score{
        float: right;
    }
    .handed{
        margin-top: 30px;
        text-align: center;
    }

    .testType{
        text-align: left;
        margin: 10px 6px;
    }
    .testType{
        margin-top: 30px;
    }
    .testList {
        display: flex;
        flex-wrap: wrap;

    .answer {
        font-size: 14px;
        border-radius: 4px;
        width: 28px;
        height: 28px;
        line-height: 30px;
        margin: 4px 6px;
        text-align: center;
        background: rgba(237, 237, 237, 0.99);
        box-sizing: border-box;
      cursor: pointer;
        border-radius: 4px;
    }
        /* 查看试题 正确和错误 */
        .rightAnswer {
            background: #52bc62;
            color: white;
            border-radius: 4px;
        }
      .defaultAnswer{
        background: #f0f2f5;
        color: black;
        border-radius: 4px;
      }

        .wrongAnswer {
            border-radius: 4px;
            color: white;
            background: rgba(255, 111, 79, 1);
        }

    /*    答题状态  考试时：1未作答，2已经作答，3正在作答 */
    .unanswered {
        /*color: rgb(57, 165, 239);*/
        border-color: rgb(57, 165, 239);
        border-radius: 4px;
    }

    .answered {
        background: rgb(57, 165, 239);
        color: white;
        border-radius: 4px;
    }

    .answering {
        color: rgb(57, 165, 239);
        border: 1px solid rgb(57, 165, 239);
        width: 28px;
        height: 28px;
        border-radius: 4px;
    }

    }

</style>
