<template>
  <div class="testDetail">
    <questionDetail :show-bottom="true" :has-result="hasResult" :list="list" :total="total" />
    <index-bottom />
  </div>
</template>

<script>
import questionDetail from './components/questionDetail'
import indexBottom from '../../components/indexBottom'
import { joinTestGetTestDetail, checkTestPaperDetail, checkTestHavePaperDetail } from '../../api/course'
export default {
  name: 'TestDetail',
  components: { questionDetail, indexBottom },
  data() {
    return {
      list: {
        chooseList: [],
        gapList: [],
        shortAnswerList: [],
        programmeList: [],
        typeStatus: true
      },
      total: 0,
      // 是否发布答案，没发布学生只能参加考试，发布答案，可以查看答案
      hasResult: 0,
      paperId: '',
      paperLogId: '',
      publishId: '',
      paperName: ''
    }
  },
  created() {
    this.paperId = this.$route.query.paperId
    this.paperLogId = this.$route.query.paperLogId
    this.publishId = this.$route.query.id
    this.paperName = this.$route.query.paperName
    this.hasResult = this.$route.query.hasResult
    this.isReleased = this.$route.query.isReleased
    this.publishType = this.$route.query.publishType
    this.getDetail()
  },
  methods: {
    getDetail() {
      console.log(this.hasResult)
      // 参加考试
      if (this.hasResult == 0) {
        joinTestGetTestDetail({ id: this.publishId, paperLogId: this.paperLogId, paperId: this.paperId }).then(res => {
          if (res.code === 200) {
            console.log(res)
            this.list = res.data
          }
        })
      } else if (this.isReleased == 0) {
        // 查看考试(无成绩)
        checkTestPaperDetail({ id: this.publishId, paperLogId: this.paperLogId, paperId: this.paperId }).then(res => {
          if (res.code === 200) {
            console.log(res)
            this.list = res.data
          }
        })
      } else {
        // 查看考试(有成绩)
        checkTestHavePaperDetail({ id: this.publishId, paperLogId: this.paperLogId, paperId: this.paperId, studentId: this.$store.state.user.userInfo.id }).then(res => {
          if (res.code === 200) {
            console.log(res)
            this.list = res.data
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
