<template>
  <el-button class="upload" type="text">
    <el-upload
      ref="upload"
      :action="uploadFileUrl"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :limit="limit"
      :on-remove="handleDelete"
      :on-preview="downLoad"
      :data="type"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-success="handleUploadSuccess"
      :show-file-list="showFileList"
      :headers="headers"
      class="upload-file-uploader"
    >
      <!-- 旧的上传按钮 -->
      <el-button v-if="fileList.length<limit&&isShow" style="float: left;margin-right: 20px;border: 0px" type="text">
        {{ btnValue }}
      </el-button>
      <!-- 上传提示 -->
      <div v-if="showTip" slot="tip" class="el-upload__tip">
        请上传
        <template v-if="fileSize"> 大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b></template>
        <template v-if="fileType"> 格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b></template>
        的文件
      </div>
    </el-upload>

  </el-button>
</template>

<script>
import store from '@/store'
export default {

  name: 'FileUpload',
  props: {
    btnValue: String,
    // 上传种类
    type: Object,
    // 是否显示按钮
    isShow: {
      type: Boolean,
      default: true
    },
    // 值
    value: [String, Object, Array],
    // 数量限制
    limit: {
      type: Number,
      default: 1
    },
    // 是否显示列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 1024
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => []
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: false
    },
    // 是否显示列表
    isShowList: {
      type: Boolean,
      default: true
    },
    //  是否是detail页
    isdetail: {
      type: Boolean,
      default: false
    },
    imgName: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      uploadFileUrl: 'api/oos/uploadFile', // 上传的图片服务器地址
      headers: {
        Authorization: 'Bearer ' + store.state.user.token
      },
      fileList: [],
      videoFlag: false,
      videoUploadPercent: 0
    }
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize)
    }
    //  利用计算属性监听两个值
  },
  watch: {
    value: {
      handler(val) {
        console.log(val)
        if (typeof (val) === 'string') {
          console.log(val)
          this.fileList = []
          return []
        } else {
          console.log('这是接收我va数据', val)
          if (val) {
            let temp = 1
            // 首先将值转为数组
            let list = Array.isArray(val) ? val : this.value.split(',')
            // 然后将数组转为对象数组
            if (list[0].name == '' || list[0].name == null) {
              console.log('传入的值为空')
              list = []
            }
            console.log(list)
            this.fileList = list.map(item => {
              if (typeof item === 'string') {
                item = { name: item, url: item }
              }
              item.uid = item.uid || new Date().getTime() + temp++
              return item
            })
          } else {
            this.fileList = []
            return []
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    downLoad(data) {
      console.log(data)
      if ('url' in data) {
        this.$download.name(data.url)
      } else {
        this.$download.name(data.name)
      }
    },
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      console.log(file)
      console.log(this.fileType)
      // 校检文件类型
      if (this.fileType[0]) {
        console.log(this.fileType)
        // 文件类型大于0再进行判定，如果是[]直接略过
        let fileExtension = ''
        if (file.name.lastIndexOf('.') > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true
          if (fileExtension && fileExtension.indexOf(type) > -1) return true
          return false
        })
        if (!isTypeOk) {
          this.$message.error(`文件格式不正确, 请上传${this.fileType.join('/')}格式文件!`)
          return false
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`)
          return false
        }
      }
      return true
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`)
    },
    // 上传失败
    handleUploadError(err) {
      console.log(err)
      this.$message.error('上传失败, 请重试')
    },
    // 上传成功回调
    handleUploadSuccess(res, file) {
      console.log(file)
      console.log('上传成功的回调触发')
      console.log(res)
      this.videoFlag = false
      this.videoUploadPercent = 0
      this.$message.success('上传成功')
      this.fileList = []
      this.fileList.push({ name: '附件', url: res.data })
      console.log(this.fileList)
      this.$emit('input', this.fileList)
    },
    // 删除文件
    handleDelete(data) {
      console.log('我点击了 删除按键')
      const index = this.fileList.map(i => i.url).indexOf(data.url)
      this.fileList.splice(index, 1)
      // this.fileList[0]=data
      console.log(this.fileList)
      this.$emit('input', this.fileList)
      this.fileList = []
    },
    // 获取文件名称
    getFileName(name) {
      if (name.lastIndexOf('/') > -1) {
        return name.slice(name.lastIndexOf('/') + 1).toLowerCase()
      } else {
        return ''
      }
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = ''
      separator = separator || ','
      for (const i in list) {
        strs += list[i].url + separator
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : ''
    },
    // 获取进度
    uploadVideoProcess(event, file, fileList) {
      console.log(event)
      console.log(file)
      console.log(fileList)
      this.videoFlag = true
      // this.videoUploadPercent = file.percentage.toFixed(0);

      // this.videoUploadPercent = file.percentage.toFixed(0)
      // this.videoUploadPercent = event.percent.toFixed(0)
      // this.videoUploadPercent = Math.floor(event.percent)
      // if (file.status === 'ready'){
      //   this.videoUploadPercent = 0
      //   this.videoFlag = true
      //   const interval = setInterval(()=>{
      //     if (this.videoUploadPercent > 99){
      //       clearInterval(interval)
      //       return
      //     }
      //     this.videoUploadPercent +=1
      //   },20)
      // }
      // if (file.status === 'success'){
      //   this.videoUploadPercent = 100
      //   this.videoFlag = false
      // }
    }
  }
}
</script>

<style scoped lang="less">
.upload{

}
</style>
