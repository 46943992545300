<template>
  <div class="questionDetail">
    <div class="box">
      <div class="tiltle">
        <span>  {{ paperName }}</span>
        <span style="font-size: 14px">  考试时间:{{ testTime }}</span>
      </div>
      <div class="left">
        <div class="line-top">
          <span style="font-size: 16px">试题列表</span>
          <span class="line-text">
            总分:<span style="color: red;display: inline-block;padding: 0 10px">{{ list.totalScore }}分</span>
            <span v-if="hasResult==1&& isReleased==1">得分:  <span style="color: red;">{{ list.ownTotalScore }}分</span></span>
          </span>
        </div>
        <!--                试题列表组件-->
        <testlist
          v-if="list.chooseList.length>0"
          :current-type="currentType"
          :score="list.ownChooseScore"
          :current-index="current"
          :testlist="list.chooseList"
          test-type="1"
          :has-result="hasResult"
          :is-released="isReleased"
          :publish-type="publishType"
          @changeTestType="changeTestType"
          @onlyChange="onlyChange"
        >选择题</testlist>
        <testlist
          v-if="list.gapList.length>0"
          :current-type="currentType"
          :score="list.ownBlankScore"
          :current-index="current"
          :testlist="list.gapList"
          test-type="2"
          :has-result="hasResult"
          :is-released="isReleased"
          :publish-type="publishType"
          @changeTestType="changeTestType"
          @onlyChange="onlyChange"
        >填空题</testlist>
        <testlist
          v-if="list.shortAnswerList.length>0"
          :current-type="currentType"
          :score="list.ownShortAnswerScore"
          :current-index="current"
          :testlist="list.shortAnswerList"
          test-type="3"
          :has-result="hasResult"
          :is-released="isReleased"
          :publish-type="publishType"
          @changeTestType="changeTestType"
          @onlyChange="onlyChange"
        >简答题</testlist>
        <testlist
          v-if="list.programmeList.length>0"
          :current-type="currentType"
          :score="list.ownProgrammeScore"
          :current-index="current"
          :testlist="list.programmeList"
          test-type="4"
          :has-result="hasResult"
          :is-released="isReleased"
          :publish-type="publishType"
          @changeTestType="changeTestType"
          @onlyChange="onlyChange"
        >编程题</testlist>
        <div v-if="hasResult==0" class="handed"> <el-button style="padding: 10px 40px" size="medium" type="primary" @click="submitPaper">交卷</el-button></div>
        <div v-else>
          <div class="comment">教师评语</div>
          <div style="padding: 0 20px;text-align: left;word-break: break-word;">{{ list.comment }}</div>
          <!--                <el-input-->
          <!--                    type="textarea"-->
          <!--                    :autosize="{ minRows: 7, maxRows: 100}"-->
          <!--                    placeholder="请填写评语"-->
          <!--                    v-model="list.comment"-->
          <!--                    disabled-->
          <!--                    :show-word-limit="true"-->
          <!--                    maxlength="200">-->
          <!--                </el-input>-->
        </div>

      </div>
      <div class="right">
        <div class="subject">
          <!--                    <div class="title" v-if="showTittle">{{type}}题目</div>-->
          <div class="content">
            <div class="text">
              <div style="float: left;"> {{ current }}、</div>
              <div v-if="publishType==0||publishType==2" style="float: left" v-html="showList[current-1].questionInformation" />
            </div>
            <div class="btn">
              <!--                            <span>题型：{{queType[list[current-1].questionType]}}</span>-->
              <!--                            <span>类型：{{queCategory[list[current-1].type]}}</span>-->
              <!--                            <span style="padding-right: 705px">难度：{{queDifficulty[list[current-1].level]}}</span>-->
              <el-button v-if="testType==4||testType==3" type="text" @click="downLoadAnswer">下载附件</el-button>
            </div>
          </div>
        </div>

        <div v-if="hasResult == 0" class="answer">
          <div class="title">答案填写</div>
          <!--                    判断题目类型-->
          <div class="content">
            <div v-if="testType ==1" class="textleft">
              <el-select v-model="showList[current-1].studentAnswer" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div v-else class="textleft">
              <el-input
                v-model="showList[current-1].studentAnswer"
                type="textarea"
                placeholder="请输入内容,填空题答案请“，”分开"
                maxlength="800"
                show-word-limit
                resize="none"
                rows="8"
              />
            </div>
            <!--                        <div class="text" v-html="list[current-1].answerInformation" v-if="list[current-1].publishType==1||list[current-1].publishType==2"></div>-->

            <div v-if="testType==4||testType==3" class="btn">
              <FileUpload :key="showList[current-1].id" :btn-value="'上传附件'" :value="showList[current-1].urlModel" :limit="1" :type="{type:5}" @input="inputList" />
            </div>
          </div>

        </div>
        <div v-if="hasResult == 1" class="answer">
          <div class="title">
            <span>答案填写</span>
            <div style="float: right">得分：<span style="color: red">{{ showList[current-1].score }}</span></div>
          </div>
          <!--                    判断题目类型-->
          <div class="content">
            <div v-if="testType ==1" class="textleft">
              <el-select v-model="showList[current-1].studentAnswer" placeholder="请选择" disabled>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  disabled
                  :value="item.value"
                />
              </el-select>
            </div>
            <div v-else class="textleft">
              <el-input
                v-model="showList[current-1].studentAnswer"
                type="textarea"
                placeholder="请输入内容,填空题答案请“，”分开"
                maxlength="800"
                disabled
                show-word-limit
                resize="none"
                rows="8"
              />
              <div class="btn"><el-button v-if="testType==4||testType==3" type="text" @click="downLoadStudent">下载我的附件</el-button></div>
            </div>
          </div>

        </div>
        <div v-if="publishType==1||publishType==2" style="text-align: left;margin-top: 10px">
          <el-button size="medium" round @click="answerStatus=!answerStatus">答案
            <i v-if="answerStatus" class="el-icon-caret-top" />
            <i v-if="!answerStatus" class="el-icon-caret-bottom" />
          </el-button>
        </div>
        <el-collapse-transition>
          <div v-show="answerStatus">
            <div class="answer">

              <div class="title">答案详情</div>
              <!--                    判断题目类型-->
              <div class="content">
                <div v-if="testType ==1 " class="textleft">
                  <el-select v-model="showList[current-1].answerInformation" placeholder="请选择" disabled>
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      disabled
                      :value="item.value"
                    />
                  </el-select>
                </div>
                <div v-else class="textleft">
                  <div v-if="publishType==1||publishType==2" class="textDetail" v-html="showList[current-1].answerInformation" />
                  <div class="btn"><el-button v-if="testType==4||testType==3" type="text" @click="downLoad">下载附件</el-button></div>
                </div>
              </div>

            </div>
          </div>
        </el-collapse-transition>

        <div class="page">
          <div class="btn">
            <el-button type="primary" @click="previous">上一题</el-button>
            <!--                        <span style="color: #39A5EF;padding-left: 19px">第{{current}}题</span>-->
            <!--                        <span style="padding-right: 19px">/共{{total}}题</span>-->
            <el-button type="primary" @click="next">下一题</el-button>
            <el-button v-if="hasResult==1" type="primary" @click="goBack">关闭</el-button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import testlist from '../../../components/testlist'
import FileUpload from '../../../components/FileUpload'
import { PaperQuestionSubmit, submitPaper } from '../../../api/course'
export default {
  name: 'QuestionDetail',
  components: {
    testlist,
    FileUpload
  },
  computed: {
    ...mapState({
      queType: state => state.dict.dictTree.ea_question_type['obj'],
      queCategory: state => state.dict.dictTree.ea_question_category['obj'],
      queDifficulty: state => state.dict.dictTree.ea_question_difficulty['obj']
    })
  },
  watch: {
    list: {
      handler(val) {
        console.log(val)
        this.dealWithList()
        this.changeShowList()
      },
      immediate: true,
      deep: true
    }
  },
  props: {
    type: {
      type: String,
      default: '考试'
    },
    showTittle: {
      type: Boolean,
      default: true
    },
    showBottom: {
      type: [Number, Boolean],
      default: 1
    },
    list: Object,
    total: {
      type: Number,
      default: 0
    },
    nowPage: Number,
    hasResult: {
      type: [String, Number],
      default: 0
    }
  },
  created() {
    console.log(this.list)
    this.dealWithList()
    this.isReleased = this.$route.query.isReleased
    this.publishType = this.$route.query.publishType
    console.log(this.publishType)
    this.paperName = this.$route.query.paperName
    this.testTime = this.$route.query.timeRange
    this.changeShowList()
  },
  data() {
    return {
      paperName: '',
      initStatus: false,
      answerStatus: false,
      // 题目类型 选择，填空，简答，编程
      testType: 1,
      options: [
        {
          value: 'A',
          label: 'A'
        },
        {
          value: 'B',
          label: 'B'
        },
        {
          value: 'C',
          label: 'C'
        },
        {
          value: 'D',
          label: 'D'
        }],
      value: '',
      text: '',
      textarea: '',
      current: 1,
      currentType: 1,
      // 是否公布成绩
      isReleased: '',
      // 公布答案类型
      publishType: '',
      // 总分
      totalScore: 0,
      queList: [],
      question: {
        title: '题目题目题目',
        content: '内容内容内容内容内容内容'
      },
      showList: [],
      // 题目
      testlist: {
        name: '题目标题',
        chooseList: [{ id: 1, titlt: '题目', answer: '答案1', answerState: 'answered', answerType: 1 }, { id: 2, titlt: '题目2', answer: '答案2', answerState: 'answering' }, { id: 3, titlt: '题目3', answer: '答案3', answerType: 1 }, { id: 4, titlt: '题目4', answer: '答案4' }, { id: 5, titlt: '题目5', answer: '答案5' }, { id: 6, titlt: '题目6', answer: '答案6' }, { id: 7, titlt: '题目7', answer: '答案7' }, { id: 8, titlt: '题目8', answer: '答案8' }, { id: 9, titlt: '题目9', answer: '答案9' }, { id: 10, titlt: '题目10', answer: '答案10' }],
        gapList: [{ id: 1, titlt: '题目', answer: '答案1' }, { id: 2, titlt: '题目2', answer: '答案2' }, { id: 3, titlt: '题目3', answer: '答案3' }, { id: 4, titlt: '题目4', answer: '答案4' }, { id: 5, titlt: '题目5', answer: '答案5' }, { id: 6, titlt: '题目6', answer: '答案6' }, { id: 7, titlt: '题目7', answer: '答案7' }, { id: 8, titlt: '题目8', answer: '答案8' }, { id: 9, titlt: '题目9', answer: '答案9' }, { id: 10, titlt: '题目10', answer: '答案10' }],
        shortAnswerList: [{ id: 1, titlt: '题目', answer: '答案1' }, { id: 2, titlt: '题目2', answer: '答案2' }, { id: 3, titlt: '题目3', answer: '答案3' }, { id: 4, titlt: '题目4', answer: '答案4' }, { id: 5, titlt: '题目5', answer: '答案5' }, { id: 6, titlt: '题目6', answer: '答案6' }, { id: 7, titlt: '题目7', answer: '答案7' }, { id: 8, titlt: '题目8', answer: '答案8' }, { id: 9, titlt: '题目9', answer: '答案9' }, { id: 10, titlt: '题目10', answer: '答案10' }],
        programmeList: [{ id: 1, titlt: '题目', answer: '答案1' }, { id: 2, titlt: '题目2', answer: '答案2' }, { id: 3, titlt: '题目3', answer: '答案3' }, { id: 4, titlt: '题目4', answer: '答案4' }, { id: 5, titlt: '题目5', answer: '答案5' }, { id: 6, titlt: '题目6', answer: '答案6' }, { id: 7, titlt: '题目7', answer: '答案7' }, { id: 8, titlt: '题目8', answer: '答案8' }, { id: 9, titlt: '题目9', answer: '答案9' }, { id: 10, titlt: '题目10', answer: '答案10' }]
      }
    }
  },
  methods: {
    // 先处理list，都加上urlModel
    dealWithList() {
      this.list.chooseList = this.listAddAnswerUrl(this.list.chooseList)
      this.list.gapList = this.listAddAnswerUrl(this.list.gapList)
      this.list.shortAnswerList = this.listAddAnswerUrl(this.list.shortAnswerList)
      this.list.programmeList = this.listAddAnswerUrl(this.list.programmeList)
      // 做题的类型判断,才能显示正确
      if (!this.list.typeStatus && !this.initStatus) {
        console.log(222)
        if (this.list.chooseList.length > 0) {
          this.testType = 1
        } else if (this.list.gapList.length > 0) {
          this.testType = 2
        } else if (this.list.shortAnswerList.length > 0) {
          this.testType = 3
        } else if (this.list.programmeList.length > 0) {
          this.testType = 4
        }
        this.initStatus = true
      }

      console.log(this.list)
    },
    listAddAnswerUrl(data) {
      if (data.length > 0) {
        data.forEach(item => {
          if (item.studentAnswerUrls !== null && item.studentAnswerUrls !== '') {
            item.urlModel = [{
              url: item.studentAnswerUrls,
              name: '附件'
            }]
          }
        })
      }
      return data
    },
    // 子传父更改显示题型和答题部分
    changeTestType(data, index) {
      // 先保存
      this.saveCurrent(data, index)
      console.log(data)
      this.testType = data
      this.current = index + 1
      // 每次点击题目，都要收起答案信息
      this.answerStatus = false
      // 再切换
      this.changeShowList()
    },
    onlyChange(data, index) {
      this.testType = data
      this.current = index + 1
      // 再切换
      this.changeShowList()
    },
    // 根据testType切换展示的题目
    changeShowList() {
      if (this.testType == 1) {
        this.showList = this.list.chooseList
        this.currentType = 1
      } else if (this.testType == 2) {
        this.showList = this.list.gapList
        this.currentType = 2
      } else if (this.testType == 3) {
        this.showList = this.list.shortAnswerList
        this.currentType = 3
      } else if (this.testType == 4) {
        this.showList = this.list.programmeList
        this.currentType = 4
      }
    },

    // 保存当前答案
    saveCurrent(type, index) {
      const obj = {
        id: this.showList[this.current - 1].studentQuestionId,
        answerInformation: this.showList[this.current - 1].studentAnswer
      }
      console.log(obj)
      if (type != 1 || type != 2) {
        obj.answerUrls = this.showList[this.current - 1].studentAnswerUrls
      }
      console.log(type)
      console.log(index)
      console.log(obj)
      PaperQuestionSubmit(obj).then(res => {
        console.log(res)
        // 及时回显
        this.timelyEcho(type, index)
      })
    },
    // 及时回显
    timelyEcho(type, index) {
      console.log(type)
      console.log(index)
      const resultUrl = this.showList[this.current - 1].studentAnswerUrls
      console.log(resultUrl)
      if (type == 3 && resultUrl == '') {
        if (resultUrl == '') {
          this.list.shortAnswerList[index].urlModel = [{
            url: this.list.shortAnswerList[index].studentAnswerUrls,
            name: '附件'
          }]
        } else {
          this.list.shortAnswerList[index].urlModel = resultUrl.concat()
        }
      } else if (type == 4 && resultUrl == '') {
        this.list.programmeList[index].urlModel = resultUrl
      }
      console.log(this.list)
    },
    // 上传组件的子组件回调
    inputList(data) {
      console.log(data)
      if (data.length > 0) {
        this.showList[this.current - 1].studentAnswerUrls = data.map(i => i.url).toString()
      } else {
        this.showList[this.current - 1].studentAnswerUrls = ''
      }
    },
    // 交卷
    submitPaper() {
      this.$confirm('是否提交试卷，若考试时间结束，则不能再更改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const obj = {
          paperPublishId: this.$route.query.id,
          id: this.showList[this.current - 1].studentQuestionId,
          answerInformation: this.showList[this.current - 1].studentAnswer
        }
        if (this.testType != 1 || this.testType != 2) {
          obj.answerUrls = this.showList[this.current - 1].studentAnswerUrls
        }
        submitPaper(obj).then(res => {
          console.log(res)
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '提交成功!'
            })
            this.$router.go(-1)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '请继续作答'
        })
      })
    },

    // 上一题
    previous() {
      if (this.hasResult == 0) {
        const obj = {
          id: this.showList[this.current - 1].studentQuestionId,
          answerInformation: this.showList[this.current - 1].studentAnswer
        }
        console.log(obj)
        if (this.testType != 1 || this.testType != 2) {
          obj.answerUrls = this.showList[this.current - 1].studentAnswerUrls
        }
        console.log(this.current - 1)
        console.log(obj)
        PaperQuestionSubmit(obj).then(res => {
          console.log(res)
          // 及时回显
          this.timelyEcho(this.testType, this.current - 1)
          this.previewChange()
        })
      } else {
        this.previewChange()
      }
    },
    // 上一题切换
    previewChange() {
      if (this.current === 1) {
        if (this.testType == 4) {
          // 需要切换到上一组
          if (this.list.shortAnswerList.length !== 0) {
            this.answerStatus = false
            this.testType = 3
            this.currentType = 3
            this.showList = this.list.shortAnswerList
            this.current = this.list.shortAnswerList.length
          } else if (this.list.gapList.length !== 0) {
            this.answerStatus = false
            this.testType = 2
            this.currentType = 2
            this.showList = this.list.gapList
            this.current = this.list.gapList.length
          } else if (this.list.chooseList.length !== 0) {
            this.answerStatus = false
            this.testType = 1
            this.currentType = 1
            this.showList = this.list.chooseList
            this.current = this.list.chooseList.length
          } else {
            this.$message.warning('当前已是第一题')
          }
        } else if (this.testType == 3) {
          if (this.list.gapList.length !== 0) {
            this.answerStatus = false
            this.testType = 2
            this.currentType = 2
            this.showList = this.list.gapList
            this.current = this.list.gapList.length
          } else if (this.list.chooseList.length !== 0) {
            this.answerStatus = false
            this.testType = 1
            this.currentType = 1
            this.showList = this.list.chooseList
            this.current = this.list.chooseList.length
          } else {
            this.$message.warning('当前已是第一题')
          }
        } else if (this.testType == 2) {
          if (this.list.chooseList.length !== 0) {
            this.answerStatus = false
            this.testType = 1
            this.currentType = 1
            this.showList = this.list.chooseList
            this.current = this.list.chooseList.length
          } else {
            this.$message.warning('当前已是第一题')
          }
        } else if (this.testType == 1) {
          this.$message.warning('当前已是第一题')
        }
      } else {
        this.answerStatus = false
        this.current--
        console.log('上一题')
      }
    },
    // 下一题
    next() {
      if (this.hasResult == 0) {
        const obj = {
          id: this.showList[this.current - 1].studentQuestionId,
          answerInformation: this.showList[this.current - 1].studentAnswer
        }
        console.log(obj)
        if (this.testType != 1 || this.testType != 2) {
          obj.answerUrls = this.showList[this.current - 1].studentAnswerUrls
        }
        console.log(this.current - 1)
        console.log(obj)
        PaperQuestionSubmit(obj).then(res => {
          console.log(res)
          // 及时回显
          this.timelyEcho(this.testType, this.current - 1)
          this.nextChange()
        })
      } else {
        this.nextChange()
      }
    },
    // 下一题切换
    nextChange() {
      if (this.current === this.showList.length) {
        console.log('本数组的最后一题')
        if (this.testType == 1) {
          // 需要切换到下一组
          if (this.list.gapList.length !== 0) {
            this.answerStatus = false
            this.testType = 2
            this.currentType = 2
            this.showList = this.list.gapList
            this.current = 1
          } else if (this.list.shortAnswerList.length !== 0) {
            this.answerStatus = false
            this.testType = 3
            this.currentType = 3
            this.showList = this.list.shortAnswerList
            this.current = 1
          } else if (this.list.programmeList.length !== 0) {
            this.answerStatus = false
            this.testType = 4
            this.currentType = 4
            this.showList = this.list.programmeList
            this.current = 1
          } else {
            this.$message.warning('当前已是最后一题')
          }
        } else if (this.testType == 2) {
          if (this.list.shortAnswerList.length !== 0) {
            this.answerStatus = false
            this.testType = 3
            this.currentType = 3
            this.showList = this.list.shortAnswerList
            this.current = 1
          } else if (this.list.programmeList.length !== 0) {
            this.answerStatus = false
            this.testType = 4
            this.currentType = 4
            this.showList = this.list.programmeList
            this.current = 1
          } else {
            this.$message.warning('当前已是最后一题')
          }
        } else if (this.testType == 3) {
          if (this.list.programmeList.length !== 0) {
            this.answerStatus = false
            this.testType = 4
            this.currentType = 4
            this.showList = this.list.programmeList
            this.current = 1
          } else {
            this.$message.warning('当前已是最后一题')
          }
        } else if (this.testType == 4) {
          this.$message.warning('当前已是最后一题')
        }
      } else {
        this.answerStatus = false
        this.current++
        console.log('下一题')
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    downLoadStudent() {
      if (this.showList[this.current - 1].studentAnswerUrls == '' || this.showList[this.current - 1].studentAnswerUrls == null || this.showList[this.current - 1].studentAnswerUrls == undefined) {
        this.$message.warning('该试题学生没有上传附件')
      } else {
        this.$download.name(this.showList[this.current - 1].studentAnswerUrls)
      }
    },
    downLoad() {
      if (this.showList[this.current - 1].answerUrls == '' || this.showList[this.current - 1].answerUrls == null || this.showList[this.current - 1].answerUrls == undefined) {
        this.$message.warning('该试题答案没有附件')
      } else {
        this.$download.name(this.showList[this.current - 1].answerUrls)
      }
    },
    downLoadAnswer() {
      if (this.showList[this.current - 1].questionUrls == '' || this.showList[this.current - 1].questionUrls == null || this.showList[this.current - 1].questionUrls == undefined) {
        this.$message.warning('该试题没有附件')
      } else {
        this.$download.name(this.showList[this.current - 1].questionUrls)
      }
    }
  }

}
</script>

<style scoped lang="less">
    .textLeft{
        text-align: left;
    }
    .questionDetail{
      min-height: 744px;
        .box{
            margin: 0 auto 17px;
            width: 1200px;
            border-radius: 6px;
            display: flex;
            flex-wrap: wrap;
            >.subject{
                padding-top: 19px;
            }
            .tiltle{
                width: 100%;
                height: 60px;
                line-height: 60px;
                display: flex;
                justify-content: space-between;
                background: white;
                margin-bottom: 20px;
                padding: 0 20px;
              border-radius:4px
            }
            .left{
                width: 350px;
                /*height: 200px;*/
                background: white;
                margin-right: 20px;
                padding: 10px;
                border-radius: 4px;
              .line-top{
                font-size: 14px;
                padding: 10px 10px 10px 6px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #c2c2c2;
              }
                .handed{
                    margin-top: 30px;
                    text-align: center;
                }

                .testType{
                    text-align: left;
                    margin: 10px 6px;
                    margin-top: 30px;
                }
            }
            .right{
                width: 830px;
                .subject{
                    background: white;
                    padding: 10px;
                    border-radius: 4px;
                }
                .answer{
                    border-radius: 4px;
                    min-height: 100px;
                    margin-top: 20px;
                    background: white;
                    padding: 10px;
                }
            }
            .textDetail{
                padding: 20px 20px 0 20px;
                text-align: left;
                min-height: 250px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                overflow: auto;
                .topic{
                    font-size: 18px;
                }
            }
            .title{
                text-align: left;
                margin: 0 19px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
            .content{
                padding: 10px;
                min-height: 300px;
                .text{
                    padding: 20px 20px 0 20px;
                    text-align: left;
                    min-height: 250px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                  display: flex;
                    //overflow: auto;
                    .topic{
                        font-size: 18px;
                    }

                }
                .btn{
                    text-align: left;
                }
            }
            .page{
                text-align: right;
                margin-right: 24px;
                margin-top: 20px;
                .el-button{

                }
                span{
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }
    .textleft{
        text-align: left;
        min-height: 200px;
    }
    .comment{
      text-align: left;
      font-size: 14px;
      padding: 15px 0;
    }

</style>
